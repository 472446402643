var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "core--components--app-button-balance" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "greenPrimary--text d-flex align-center" },
          [
            _c("v-progress-circular", {
              staticClass: "mr-1",
              attrs: {
                size: "11",
                width: "2",
                indeterminate: "",
                color: "primary",
              },
            }),
            _vm._v(" " + _vm._s(_vm.loadingText) + " "),
            !_vm.noDots
              ? _c("span", { staticClass: "loading-dots" }, [
                  _vm._v(_vm._s(_vm.loadingDots)),
                ])
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "d-flex align-center justify-end" },
          [
            !_vm.text
              ? _c(
                  "div",
                  { staticClass: "greenPrimary--text mew-body BalanceLabel" },
                  [
                    _vm._v(
                      " Balance: " + _vm._s(_vm.balanceFormatted.value) + " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "primary--text mew-body", attrs: { else: "" } },
              [_vm._v(" " + _vm._s(_vm.text) + " ")]
            ),
            _vm.balanceFormatted.tooltipText
              ? _c("mew-tooltip", {
                  staticClass: "pl-1",
                  staticStyle: { height: "21px" },
                  attrs: { text: _vm.balanceFormatted.tooltipText },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }